var $jscomp = $jscomp || {};
$jscomp.scope = {};

$jscomp.arrayIteratorImpl = function (a) {
  var b = 0;
  return function () {
    return b < a.length ? {
      done: !1,
      value: a[b++]
    } : {
      done: !0
    };
  };
};

$jscomp.arrayIterator = function (a) {
  return {
    next: $jscomp.arrayIteratorImpl(a)
  };
};

$jscomp.makeIterator = function (a) {
  var b = "undefined" != typeof Symbol && Symbol.iterator && a[Symbol.iterator];
  return b ? b.call(a) : $jscomp.arrayIterator(a);
};

var ussInit = function () {
  uss.getWindowScroller(!0);
  uss.getPageScroller(!0);
  uss.getScrollbarsMaxDimension(!0);

  var a = 60,
      b = function () {
    0 < a && (a--, uss.calcFramesTimes(void 0, void 0, b));
  };

  b();
},
    onResize = function () {
  window.removeEventListener("pointerover", onResize, {
    passive: !0
  });
  window.removeEventListener("pointerdown", onResize, {
    passive: !0
  });
  window.removeEventListener("touchstart", onResize, {
    passive: !0
  });
  window.removeEventListener("mousemove", onResize, {
    passive: !0
  });
  window.removeEventListener("keydown", onResize, {
    passive: !0
  });
  window.removeEventListener("focus", onResize, {
    passive: !0
  });
  resizeHandled = !1;
  uss._windowWidth = window.innerWidth;
  uss._windowHeight = window.innerHeight;

  for (var a = $jscomp.makeIterator(uss._containersData.values()), b = a.next(); !b.done; b = a.next()) b = b.value, b[16] = void 0, b[17] = void 0, b[18] = void 0, b[19] = void 0, b[20] = void 0, b[21] = void 0, b[22] = void 0, b[23] = void 0, b[24] = void 0, b[25] = void 0, b[26] = void 0, b[27] = void 0;

  a = $jscomp.makeIterator(uss._onResizeEndCallbacks);

  for (b = a.next(); !b.done; b = a.next()) b = b.value, b();
},
    INITIAL_WINDOW_WIDTH = window.innerWidth,
    INITIAL_WINDOW_HEIGHT = window.innerHeight,
    DEFAULT_XSTEP_LENGTH = 16 + 7 / 1508 * (INITIAL_WINDOW_WIDTH - 412),
    DEFAULT_YSTEP_LENGTH = Math.max(1, Math.abs(38 - 20 / 140 * (INITIAL_WINDOW_HEIGHT - 789))),
    DEFAULT_MIN_ANIMATION_FRAMES = INITIAL_WINDOW_HEIGHT / DEFAULT_YSTEP_LENGTH,
    DEFAULT_FRAME_TIME = 16.6,
    DEFAULT_REGEX_LOGGER_DISABLED = /disabled/i,
    DEFAULT_REGEX_LOGGER_LEGACY = /legacy/i,
    DEFAULT_REGEX_ALIGNMENT_NEAREST = /nearest/i,
    DEFAULT_REGEX_OVERFLOW = /(auto|scroll)/,
    DEFAULT_REGEX_OVERFLOW_HIDDEN = /(auto|scroll|hidden)/,
    DEFAULT_REGEX_OVERFLOW_WITH_VISIBLE = /(auto|scroll|visible)/,
    DEFAULT_REGEX_OVERFLOW_HIDDEN_WITH_VISIBLE = /(auto|scroll|hidden|visible)/,
    DEFAULT_XSTEP_LENGTH_CALCULATOR = function (a, b, d, c, e, f, g) {
  a = c / uss._minAnimationFrame;
  return 1 > a ? 1 : a > uss._xStepLength ? uss._xStepLength : a;
},
    DEFAULT_YSTEP_LENGTH_CALCULATOR = function (a, b, d, c, e, f, g) {
  a = c / uss._minAnimationFrame;
  return 1 > a ? 1 : a > uss._yStepLength ? uss._yStepLength : a;
},
    DEFAULT_ERROR_LOGGER = function (a, b, d) {
  if (!DEFAULT_REGEX_LOGGER_DISABLED.test(uss._debugMode)) {
    var c = "string" === typeof d;
    if (!c) if (null === d || void 0 === d) d = String(d);else if (d === window) d = "window";else if (Array.isArray(d)) d = "[" + d.toString() + "]";else if (d instanceof Element || d.tagName && (d.id || d.className)) {
      var e = d.id ? "#" + d.id : "",
          f = d.className ? "." + d.className : "";
      d = d.tagName.toLowerCase() + e + f;
    } else d = d.name || d.outerHTML || d.toString().replace(RegExp("\n", "g"), "");
    40 < d.length && (d = d.slice(0, 40) + " ...");
    c && (d = "\"" + d + "\"");
    if (DEFAULT_REGEX_LOGGER_LEGACY.test(uss._debugMode)) throw console.log("UniversalSmoothScroll API (documentation at: https://github.com/CristianDavideConte/universalSmoothScroll)\n"), console.error("USS ERROR\n", a, "was expecting", b + ", but received", d + "."), "USS fatal error (execution stopped)";
    console.group("UniversalSmoothScroll API (documentation at: https://github.com/CristianDavideConte/universalSmoothScroll)");
    console.log("%cUSS ERROR", "font-family:system-ui; font-weight:800; font-size:40px; background:#eb445a; color:black; border-radius:5px; padding:0.4vh 0.5vw; margin:1vh 0");
    console.log("%c" + a + "%cwas expecting " + b, "font-style:italic; font-family:system-ui; font-weight:700; font-size:17px; background:#2dd36f; color:black; border-radius:5px 0px 0px 5px; padding:0.4vh 0.5vw; margin-left:13px", "font-family:system-ui; font-weight:600; font-size:17px; background:#2dd36f; color:black; border-radius:0px 5px 5px 0px; padding:0.4vh 0.5vw");
    console.log("%cBut received%c" + d, "font-family:system-ui; font-weight:600; font-size:17px; background:#eb445a; color:black; border-radius:5px 0px 0px 5px; padding:0.4vh 0.5vw; margin-left:13px", "font-style:italic; font-family:system-ui; font-weight:700; font-size:17px; background:#eb445a; color:black; border-radius:0px 5px 5px 0px; padding:0.4vh 0.5vw");
    console.groupCollapsed("%cStack Trace", "font-family:system-ui; font-weight:500; font-size:17px; background:#3171e0; color:#f5f6f9; border-radius:5px; padding:0.3vh 0.5vw; margin-left:13px");
    console.trace("");
    console.groupEnd("Stack Trace");
    console.groupEnd("UniversalSmoothScroll API (documentation at: https://github.com/CristianDavideConte/universalSmoothScroll)");
    throw "USS fatal error (execution stopped)";
  }
},
    DEFAULT_WARNING_LOGGER = function (a, b, d) {
  d = void 0 === d ? !0 : d;

  if (!DEFAULT_REGEX_LOGGER_DISABLED.test(uss._debugMode)) {
    var c = "string" === typeof a;
    if (!c) if (null === a || void 0 === a) a = String(a);else if (a === window) a = "window";else if (Array.isArray(a)) a = "[" + a.toString() + "]";else if (a instanceof Element || a.tagName && (a.id || a.className)) {
      var e = a.id ? "#" + a.id : "",
          f = a.className ? "." + a.className : "";
      a = a.tagName.toLowerCase() + e + f;
    } else a = a.name || a.outerHTML || a.toString().replace(RegExp("\n", "g"), "");
    40 < a.length && (a = a.slice(0, 40) + " ...");
    c && d && (a = "\"" + a + "\"");
    DEFAULT_REGEX_LOGGER_LEGACY.test(uss._debugMode) ? (console.log("UniversalSmoothScroll API (documentation at: https://github.com/CristianDavideConte/universalSmoothScroll)\n"), console.warn("USS WARNING\n", a, b + ".")) : (console.groupCollapsed("UniversalSmoothScroll API (documentation at: https://github.com/CristianDavideConte/universalSmoothScroll)"), console.log("%cUSS WARNING", "font-family:system-ui; font-weight:800; font-size:40px; background:#fcca03; color:black; border-radius:5px; padding:0.4vh 0.5vw; margin:1vh 0"), console.log("%c" + a + "%c" + b, "font-style:italic; font-family:system-ui; font-weight:700; font-size:17px; background:#fcca03; color:black; border-radius:5px 0px 0px 5px; padding:0.4vh 0.5vw; margin-left:13px", "font-family:system-ui; font-weight:600; font-size:17px; background:#fcca03; color:black; border-radius:0px 5px 5px 0px; padding:0.4vh 0.5vw"), console.groupCollapsed("%cStack Trace", "font-family:system-ui; font-weight:500; font-size:17px; background:#3171e0; color:#f5f6f9; border-radius:5px; padding:0.3vh 0.5vw; margin-left:13px"), console.trace(""), console.groupEnd("Stack Trace"), console.groupEnd("UniversalSmoothScroll API (documentation at: https://github.com/CristianDavideConte/universalSmoothScroll)"));
  }
};

window.uss = {
  _containersData: new Map(),
  _xStepLength: DEFAULT_XSTEP_LENGTH,
  _yStepLength: DEFAULT_YSTEP_LENGTH,
  _minAnimationFrame: DEFAULT_MIN_ANIMATION_FRAMES,
  _windowWidth: INITIAL_WINDOW_WIDTH,
  _windowHeight: INITIAL_WINDOW_HEIGHT,
  _scrollbarsMaxDimension: null,
  _framesTime: DEFAULT_FRAME_TIME,
  _framesTimes: [],
  _windowScroller: null,
  _pageScroller: null,
  _reducedMotion: "matchMedia" in window && window.matchMedia("(prefers-reduced-motion)").matches,
  _onResizeEndCallbacks: [],
  _debugMode: "",
  _errorLogger: DEFAULT_ERROR_LOGGER,
  _warningLogger: DEFAULT_WARNING_LOGGER,
  isXScrolling: function (a, b) {
    a = void 0 === a ? uss._pageScroller : a;
    b = void 0 === b ? {
      debugString: "isXScrolling"
    } : b;

    var d = uss._containersData.get(a);

    if (d) return !!d[0];
    if (a === window || a instanceof Element) return uss._containersData.set(a, []), !1;

    uss._errorLogger(b.debugString, "the container to be an Element or the Window", a);
  },
  isYScrolling: function (a, b) {
    a = void 0 === a ? uss._pageScroller : a;
    b = void 0 === b ? {
      debugString: "isYScrolling"
    } : b;

    var d = uss._containersData.get(a);

    if (d) return !!d[1];
    if (a === window || a instanceof Element) return uss._containersData.set(a, []), !1;

    uss._errorLogger(b.debugString, "the container to be an Element or the Window", a);
  },
  isScrolling: function (a, b) {
    a = void 0 === a ? uss._pageScroller : a;
    b = void 0 === b ? {
      debugString: "isScrolling"
    } : b;

    var d = uss._containersData.get(a);

    if (d) return !(!d[0] && !d[1]);
    if (a === window || a instanceof Element) return uss._containersData.set(a, []), !1;

    uss._errorLogger(b.debugString, "the container to be an Element or the Window", a);
  },
  getFinalXPosition: function (a, b) {
    a = void 0 === a ? uss._pageScroller : a;
    b = void 0 === b ? {
      debugString: "getFinalXPosition"
    } : b;
    var d = uss._containersData.get(a) || [];
    return 0 === d[2] ? 0 : d[2] || uss.getScrollXCalculator(a, b)();
  },
  getFinalYPosition: function (a, b) {
    a = void 0 === a ? uss._pageScroller : a;
    b = void 0 === b ? {
      debugString: "getFinalYPosition"
    } : b;
    var d = uss._containersData.get(a) || [];
    return 0 === d[3] ? 0 : d[3] || uss.getScrollYCalculator(a, b)();
  },
  getScrollXDirection: function (a, b) {
    a = void 0 === a ? uss._pageScroller : a;
    b = void 0 === b ? {
      debugString: "getScrollXDirection"
    } : b;

    var d = uss._containersData.get(a);

    if (d) return d[4] || 0;
    if (a === window || a instanceof Element) return uss._containersData.set(a, []), 0;

    uss._errorLogger(b.debugString, "the container to be an Element or the Window", a);
  },
  getScrollYDirection: function (a, b) {
    a = void 0 === a ? uss._pageScroller : a;
    b = void 0 === b ? {
      debugString: "getScrollYDirection"
    } : b;

    var d = uss._containersData.get(a);

    if (d) return d[5] || 0;
    if (a === window || a instanceof Element) return uss._containersData.set(a, []), 0;

    uss._errorLogger(b.debugString, "the container to be an Element or the Window", a);
  },
  getXStepLengthCalculator: function (a, b, d) {
    a = void 0 === a ? uss._pageScroller : a;
    b = void 0 === b ? !1 : b;
    d = void 0 === d ? {
      debugString: "getXStepLengthCalculator"
    } : d;

    var c = uss._containersData.get(a);

    if (c) return b ? c[14] : c[12];
    a === window || a instanceof Element ? uss._containersData.set(a, []) : uss._errorLogger(d.debugString, "the container to be an Element or the Window", a);
  },
  getYStepLengthCalculator: function (a, b, d) {
    a = void 0 === a ? uss._pageScroller : a;
    b = void 0 === b ? !1 : b;
    d = void 0 === d ? {
      debugString: "getYStepLengthCalculator"
    } : d;

    var c = uss._containersData.get(a);

    if (c) return b ? c[15] : c[13];
    a === window || a instanceof Element ? uss._containersData.set(a, []) : uss._errorLogger(d.debugString, "the container to be an Element or the Window", a);
  },
  getXStepLength: function () {
    return uss._xStepLength;
  },
  getYStepLength: function () {
    return uss._yStepLength;
  },
  getMinAnimationFrame: function () {
    return uss._minAnimationFrame;
  },
  getWindowWidth: function () {
    return uss._windowWidth;
  },
  getWindowHeight: function () {
    return uss._windowHeight;
  },
  getScrollbarsMaxDimension: function (a) {
    if ((void 0 === a ? 0 : a) || !Number.isFinite(uss._scrollbarsMaxDimension)) {
      a = document.createElement("style");
      var b = document.createElement("div");
      b.id = "_uss-scrollbox";
      a.appendChild(document.createTextNode("#_uss-scrollbox { display:block; width:100px; height:100px; overflow-x:scroll; border:none; padding:0px; }#_uss-scrollbox::-webkit-scrollbar { display:block; width:initial; height:initial; }"));
      document.head.appendChild(a);
      document.body.appendChild(b);
      uss._scrollbarsMaxDimension = b.offsetHeight - b.clientHeight;
      document.body.removeChild(b);
      document.head.removeChild(a);
    }

    return uss._scrollbarsMaxDimension;
  },
  getWindowScroller: function (a) {
    if ((void 0 === a ? 0 : a) || !uss._windowScroller) a = function (b) {
      var d = window.scrollX,
          c = window.scrollY,
          e = b.scrollLeft,
          f = b.scrollTop;
      window.scroll(0, 0);
      b.scroll(0, 0);
      window.scroll(100, 100);
      var g = 0 !== window.scrollX || 0 !== window.scrollY,
          k = g && b.scrollLeft === window.scrollX && b.scrollTop === window.scrollY;
      window.scroll(d, c);
      b.scroll(e, f);
      return g ? k ? (uss._windowScroller = b, !0) : !1 : (d = (b = uss._containersData.get(window)) || [], d[16] = 0, d[17] = 0, b || uss._containersData.set(window, d), uss._windowScroller = window, !0);
    }, a(document.documentElement) || a(document.body) || (uss._windowScroller = window);
    return uss._windowScroller;
  },
  getFramesTime: function (a, b, d) {
    d = void 0 === d ? {
      debugString: "getFramesTime",
      requestPhase: 0
    } : d;
    (void 0 === a ? 0 : a) && uss.calcFramesTimes(void 0, void 0, b, d);
    return uss._framesTime;
  },
  getPageScroller: function (a, b) {
    b = void 0 === b ? {
      debugString: "getPageScroller"
    } : b;

    if ((void 0 === a ? 0 : a) || !uss._pageScroller) {
      var d = uss.getMaxScrollX(document.documentElement, !0, b),
          c = uss.getMaxScrollY(document.documentElement, !0, b),
          e = uss.getMaxScrollX(document.body, !0, b),
          f = uss.getMaxScrollY(document.body, !0, b);
      uss._pageScroller = d > e && c >= f || d >= e && c > f ? document.documentElement : e > d && f >= c || e >= d && f > c ? document.body : document.scrollingElement || window;
    }

    return uss._pageScroller;
  },
  getReducedMotionState: function () {
    return uss._reducedMotion;
  },
  getOnResizeEndCallbacks: function () {
    return uss._onResizeEndCallbacks;
  },
  getDebugMode: function () {
    return uss._debugMode;
  },
  setXStepLengthCalculator: function (a, b, d, c) {
    b = void 0 === b ? uss._pageScroller : b;
    d = void 0 === d ? !1 : d;
    c = void 0 === c ? {
      debugString: "setXStepLengthCalculator"
    } : c;
    var e = void 0 !== a;
    if ("function" !== typeof a && e) uss._errorLogger(c.debugString, "the newCalculator to be a function", a);else {
      var f = uss._containersData.get(b),
          g = f || [];

      if (!f) {
        if (b !== window && !(b instanceof Element)) {
          uss._errorLogger(c.debugString, "the container to be an Element or the Window", b);

          return;
        }

        uss._containersData.set(b, g);
      }

      d ? g[14] = a : (g[12] = a, e && (g[14] = void 0));
    }
  },
  setYStepLengthCalculator: function (a, b, d, c) {
    b = void 0 === b ? uss._pageScroller : b;
    d = void 0 === d ? !1 : d;
    c = void 0 === c ? {
      debugString: "setYStepLengthCalculator"
    } : c;
    var e = void 0 !== a;
    if ("function" !== typeof a && e) uss._errorLogger(c.debugString, "the newCalculator to be a function", a);else {
      var f = uss._containersData.get(b),
          g = f || [];

      if (!f) {
        if (b !== window && !(b instanceof Element)) {
          uss._errorLogger(c.debugString, "the container to be an Element or the Window", b);

          return;
        }

        uss._containersData.set(b, g);
      }

      d ? g[15] = a : (g[13] = a, e && (g[15] = void 0));
    }
  },
  setStepLengthCalculator: function (a, b, d, c) {
    b = void 0 === b ? uss._pageScroller : b;
    d = void 0 === d ? !1 : d;
    c = void 0 === c ? {
      debugString: "setStepLengthCalculator"
    } : c;
    var e = void 0 !== a;
    if ("function" !== typeof a && e) uss._errorLogger(c.debugString, "the newCalculator to be a function", a);else {
      var f = uss._containersData.get(b),
          g = f || [];

      if (!f) {
        if (b !== window && !(b instanceof Element)) {
          uss._errorLogger(c.debugString, "the container to be an Element or the Window", b);

          return;
        }

        uss._containersData.set(b, g);
      }

      d ? (g[14] = a, g[15] = a) : (g[12] = a, g[13] = a, e && (g[14] = void 0, g[15] = void 0));
    }
  },
  setXStepLength: function (a, b) {
    a = void 0 === a ? DEFAULT_XSTEP_LENGTH : a;
    b = void 0 === b ? {
      debugString: "setXStepLength"
    } : b;
    !Number.isFinite(a) || 0 >= a ? uss._errorLogger(b.debugString, "the newXStepLength to be a positive number", a) : uss._xStepLength = a;
  },
  setYStepLength: function (a, b) {
    a = void 0 === a ? DEFAULT_YSTEP_LENGTH : a;
    b = void 0 === b ? {
      debugString: "setYStepLength"
    } : b;
    !Number.isFinite(a) || 0 >= a ? uss._errorLogger(b.debugString, "the newYStepLength to be a positive number", a) : uss._yStepLength = a;
  },
  setStepLength: function (a, b) {
    b = void 0 === b ? {
      debugString: "setStepLength"
    } : b;
    !Number.isFinite(a) || 0 >= a ? uss._errorLogger(b.debugString, "the newStepLength to be a positive number", a) : (uss._xStepLength = a, uss._yStepLength = a);
  },
  setMinAnimationFrame: function (a, b) {
    a = void 0 === a ? DEFAULT_MIN_ANIMATION_FRAMES : a;
    b = void 0 === b ? {
      debugString: "setMinAnimationFrame"
    } : b;
    !Number.isFinite(a) || 0 >= a ? uss._errorLogger(b.debugString, "the newMinAnimationFrame to be a positive number", a) : uss._minAnimationFrame = a;
  },
  setPageScroller: function (a, b) {
    b = void 0 === b ? {
      debugString: "setPageScroller"
    } : b;

    if (!uss._containersData.get(a)) {
      if (a !== window && !(a instanceof Element)) {
        uss._errorLogger(b.debugString, "the newPageScroller to be an Element or the Window", a);

        return;
      }

      uss._containersData.set(a, []);
    }

    uss._pageScroller = a;
  },
  addOnResizeEndCallback: function (a, b) {
    b = void 0 === b ? {
      debugString: "addOnResizeEndCallback"
    } : b;
    "function" !== typeof a ? uss._errorLogger(b.debugString, "the newCallback to be a function", a) : uss._onResizeEndCallbacks.push(a);
  },
  setDebugMode: function (a, b) {
    a = void 0 === a ? "" : a;
    b = void 0 === b ? {
      debugString: "setDebugMode"
    } : b;
    "string" === typeof a ? uss._debugMode = a : console.error("USS ERROR\n", b.debugString, "was expecting the newDebugMode to be \"disabled\", \"legacy\" or any other string, but received", a + ".");
  },
  setErrorLogger: function (a, b) {
    a = void 0 === a ? DEFAULT_ERROR_LOGGER : a;
    b = void 0 === b ? {
      debugString: "setErrorLogger"
    } : b;
    "function" !== typeof a ? uss._errorLogger(b.debugString, "the newErrorLogger to be a function", a) : uss._errorLogger = a;
  },
  setWarningLogger: function (a, b) {
    a = void 0 === a ? DEFAULT_WARNING_LOGGER : a;
    b = void 0 === b ? {
      debugString: "setWarningLogger"
    } : b;
    "function" !== typeof a ? uss._errorLogger(b.debugString, "the newWarningLogger to be a function", a) : uss._warningLogger = a;
  },
  calcFramesTimes: function (a, b, d, c) {
    c = void 0 === c ? {
      debugString: "calcFramesTimes",
      requestPhase: 0
    } : c;
    if (!uss._framesTimes[-1] || uss._framesTimes[-1] === c.requestPhase) if (!Number.isFinite(a) || 0 > a) c.requestPhase = 1, uss._framesTimes[-1] = 1, window.requestAnimationFrame(function (k) {
      return uss.calcFramesTimes(k, b, d, c);
    });else if (!Number.isFinite(b) || 0 > b) c.requestPhase = 2, uss._framesTimes[-1] = 2, window.requestAnimationFrame(function (k) {
      return uss.calcFramesTimes(a, k, d, c);
    });else {
      uss._framesTimes[-1] = 0;

      uss._framesTimes.unshift(b - a);

      10 < uss._framesTimes.length && uss._framesTimes.pop();

      for (var e = 0, f = $jscomp.makeIterator(uss._framesTimes), g = f.next(); !g.done; g = f.next()) e += g.value;

      uss._framesTime = e / uss._framesTimes.length;
      "function" === typeof d && d();
    }
  },
  calcXScrollbarDimension: function (a, b, d) {
    b = void 0 === b ? !1 : b;
    d = void 0 === d ? {
      debugString: "calcXScrollbarDimension"
    } : d;

    var c = uss._containersData.get(a),
        e = c || [];

    if (!c) {
      if (a !== window && !(a instanceof Element)) {
        uss._errorLogger(d.debugString, "the container to be an Element or the Window", a);

        return;
      }

      uss._containersData.set(a, e);
    } else if (!b && Number.isFinite(e[18])) return e[18];

    if (0 === uss.getScrollbarsMaxDimension(!1)) var f = 0;else if (a === window) a = uss.getPageScroller(!1, d), d.debugString = "calcXScrollbarDimension(uss.getPageScroller(false))", f = a === window ? 0 : uss.calcXScrollbarDimension(a, b, d);else {
      f = window.getComputedStyle(a);
      var g = Number.parseInt(f.width);
      b = a.clientWidth;
      d = a.style.overflowY;
      c = a.scrollLeft;
      a.style.overflowY = "hidden";
      f = Number.parseInt(f.width) - g;
      0 === f ? f = a.clientWidth - b : 0 > f && (f = 0);
      a.style.overflowY = d;
      a.scrollLeft = c;
    }
    return e[18] = f;
  },
  calcYScrollbarDimension: function (a, b, d) {
    b = void 0 === b ? !1 : b;
    d = void 0 === d ? {
      debugString: "calcYScrollbarDimension"
    } : d;

    var c = uss._containersData.get(a),
        e = c || [];

    if (!c) {
      if (a !== window && !(a instanceof Element)) {
        uss._errorLogger(d.debugString, "the container to be an Element or the Window", a);

        return;
      }

      uss._containersData.set(a, e);
    } else if (!b && Number.isFinite(e[19])) return e[19];

    if (0 === uss.getScrollbarsMaxDimension(!1)) var f = 0;else if (a === window) a = uss.getPageScroller(!1, d), d.debugString = "calcYScrollbarDimension(uss.getPageScroller(false))", f = a === window ? 0 : uss.calcYScrollbarDimension(a, b, d);else {
      f = window.getComputedStyle(a);
      var g = Number.parseInt(f.height);
      b = a.clientHeight;
      d = a.style.overflowX;
      c = a.scrollTop;
      a.style.overflowX = "hidden";
      f = Number.parseInt(f.height) - g;
      0 === f ? f = a.clientHeight - b : 0 > f && (f = 0);
      a.style.overflowX = d;
      a.scrollTop = c;
    }
    return e[19] = f;
  },
  calcScrollbarsDimensions: function (a, b, d) {
    b = void 0 === b ? !1 : b;
    d = void 0 === d ? {
      debugString: "calcScrollbarsDimensions"
    } : d;

    var c = uss._containersData.get(a),
        e = c || [];

    if (!c) {
      if (a !== window && !(a instanceof Element)) {
        uss._errorLogger(d.debugString, "the container to be an Element or the Window", a);

        return;
      }

      uss._containersData.set(a, e);
    } else if (!b && Number.isFinite(e[18]) && Number.isFinite(e[19])) return [e[18], e[19]];

    if (0 === uss.getScrollbarsMaxDimension(!1)) b = [0, 0];else if (a === window) a = uss.getPageScroller(!1, d), d.debugString = "calcScrollbarsDimensions(uss.getPageScroller(false))", b = a === window ? [0, 0] : uss.calcScrollbarsDimensions(a, b, d);else {
      b = [];
      d = window.getComputedStyle(a);
      c = Number.parseInt(d.width);
      var f = Number.parseInt(d.height),
          g = a.clientWidth,
          k = a.clientHeight,
          m = a.style.overflowX,
          h = a.style.overflowY,
          n = a.scrollLeft,
          l = a.scrollTop;
      a.style.overflowX = "hidden";
      a.style.overflowY = "hidden";
      b[0] = Number.parseInt(d.width) - c;
      b[1] = Number.parseInt(d.height) - f;
      0 === b[0] ? b[0] = a.clientWidth - g : 0 > b[0] && (b[0] = 0);
      0 === b[1] ? b[1] = a.clientHeight - k : 0 > b[1] && (b[1] = 0);
      a.style.overflowX = m;
      a.style.overflowY = h;
      a.scrollLeft = n;
      a.scrollTop = l;
    }
    e[18] = b[0];
    e[19] = b[1];
    return b;
  },
  calcBordersDimensions: function (a, b, d) {
    b = void 0 === b ? !1 : b;
    d = void 0 === d ? {
      debugString: "calcBordersDimensions"
    } : d;

    var c = uss._containersData.get(a),
        e = c || [];

    if (!c) {
      if (a !== window && !(a instanceof Element)) {
        uss._errorLogger(d.debugString, "the container to be an Element the Window", a);

        return;
      }

      uss._containersData.set(a, e);
    } else if (!b && Number.isFinite(e[20]) && Number.isFinite(e[21]) && Number.isFinite(e[22]) && Number.isFinite(e[23])) return [e[20], e[21], e[22], e[23]];

    a === window ? (a = uss.getPageScroller(!1, d), d.debugString = "calcBordersDimensions(uss.getPageScroller())", b = a === window ? [0, 0, 0, 0] : uss.calcBordersDimensions(a, b, d)) : (b = window.getComputedStyle(a), b = [Number.parseFloat(b.borderTopWidth), Number.parseFloat(b.borderRightWidth), Number.parseFloat(b.borderBottomWidth), Number.parseFloat(b.borderLeftWidth)]);
    e[20] = b[0];
    e[21] = b[1];
    e[22] = b[2];
    e[23] = b[3];
    return b;
  },
  getScrollXCalculator: function (a, b) {
    a = void 0 === a ? uss._pageScroller : a;
    b = void 0 === b ? {
      debugString: "getScrollXCalculator"
    } : b;

    var d = uss._containersData.get(a),
        c = d || [];

    if (!c[28]) {
      if (a === window) c[28] = function () {
        return window.scrollX;
      }, c[29] = function () {
        return window.scrollY;
      };else if (a instanceof Element) c[28] = function () {
        return a.scrollLeft;
      }, c[29] = function () {
        return a.scrollTop;
      };else {
        uss._errorLogger(b.debugString, "the container to be an Element or the Window", a);

        return;
      }
      d || uss._containersData.set(a, c);
    }

    return c[28];
  },
  getScrollYCalculator: function (a, b) {
    a = void 0 === a ? uss._pageScroller : a;
    b = void 0 === b ? {
      debugString: "getScrollYCalculator"
    } : b;
    var d = uss._containersData.get(a) || [];

    if (!d[29]) {
      if (a === window) d[28] = function () {
        return window.scrollX;
      }, d[29] = function () {
        return window.scrollY;
      };else if (a instanceof Element) d[28] = function () {
        return a.scrollLeft;
      }, d[29] = function () {
        return a.scrollTop;
      };else {
        uss._errorLogger(b.debugString, "the container to be an Element or the Window", a);

        return;
      }

      uss._containersData.set(a, d);
    }

    return d[29];
  },
  getMaxScrollX: function (a, b, d) {
    a = void 0 === a ? uss._pageScroller : a;
    b = void 0 === b ? !1 : b;
    d = void 0 === d ? {
      debugString: "getMaxScrollX"
    } : d;

    var c = uss._containersData.get(a),
        e = c || [];

    if (!b && Number.isFinite(e[16])) return e[16];
    if (a === window) return c || uss._containersData.set(a, e), a = uss.getWindowScroller(!1, d), a = a === window ? 0 : uss.getMaxScrollX(a, b, d), e[16] = a;

    if (!c) {
      if (!(a instanceof Element)) {
        uss._errorLogger(d.debugString, "the container to be an Element or the Window", a);

        return;
      }

      uss._containersData.set(a, e);
    }

    b = a.scrollLeft;
    a.scrollLeft = 1073741824;
    d = a.scrollLeft;
    a.scrollLeft = b;
    return e[16] = d;
  },
  getMaxScrollY: function (a, b, d) {
    a = void 0 === a ? uss._pageScroller : a;
    b = void 0 === b ? !1 : b;
    d = void 0 === d ? {
      debugString: "getMaxScrollY"
    } : d;

    var c = uss._containersData.get(a),
        e = c || [];

    if (!b && Number.isFinite(e[17])) return e[17];
    if (a === window) return c || uss._containersData.set(a, e), a = uss.getWindowScroller(!1, d), a = a === window ? 0 : uss.getMaxScrollY(a, b, d), e[17] = a;

    if (!c) {
      if (!(a instanceof Element)) {
        uss._errorLogger(d.debugString, "the container to be an Element or the Window", a);

        return;
      }

      uss._containersData.set(a, e);
    }

    b = a.scrollTop;
    a.scrollTop = 1073741824;
    d = a.scrollTop;
    a.scrollTop = b;
    return e[17] = d;
  },
  getXScrollableParent: function (a, b, d) {
    b = void 0 === b ? !1 : b;
    d = void 0 === d ? {
      debugString: "getXScrollableParent"
    } : d;

    var c = uss._containersData.get(a),
        e = c || [],
        f = b ? e[25] : e[24];

    if (f || null === f) return f;
    f = document.body;
    var g = document.documentElement;

    if (b) {
      b = function (n) {
        return e[25] = n;
      };

      var k = DEFAULT_REGEX_OVERFLOW_HIDDEN;
      var m = DEFAULT_REGEX_OVERFLOW_HIDDEN_WITH_VISIBLE;
    } else b = function (n) {
      return e[24] = n;
    }, k = DEFAULT_REGEX_OVERFLOW, m = DEFAULT_REGEX_OVERFLOW_WITH_VISIBLE;

    if (a === window || a === g) return b(null), c || uss._containersData.set(a, e), null;

    if (!c) {
      if (!(a instanceof Element)) {
        uss._errorLogger(d.debugString, "the element to be an Element or the Window", a);

        return;
      }

      uss._containersData.set(a, e);
    }

    c = window.getComputedStyle(a);
    if ("fixed" === c.position) return b(null), null;
    var h = "absolute" !== c.position;

    for (a = a.parentElement; a && a !== f && a !== g;) {
      c = window.getComputedStyle(a);
      if ((h || "static" !== c.position) && k.test(c.overflowX) && 1 <= uss.getMaxScrollX(a)) return b(a), a;
      if ("fixed" === c.position) return b(null), null;
      a = a.parentElement;
    }

    if (a === f && "absolute" === c.position) return b(null), null;

    for (d = uss.getWindowScroller(!1, d); a;) {
      c = window.getComputedStyle(a);
      if (m.test(c.overflowX) && 1 <= uss.getMaxScrollX(a)) return d === a && (a = window), b(a), a;
      a = a.parentElement;
    }

    b(null);
    return null;
  },
  getYScrollableParent: function (a, b, d) {
    b = void 0 === b ? !1 : b;
    d = void 0 === d ? {
      debugString: "getYScrollableParent"
    } : d;

    var c = uss._containersData.get(a),
        e = c || [],
        f = b ? e[27] : e[26];

    if (f || null === f) return f;
    f = document.body;
    var g = document.documentElement;

    if (b) {
      b = function (n) {
        return e[27] = n;
      };

      var k = DEFAULT_REGEX_OVERFLOW_HIDDEN;
      var m = DEFAULT_REGEX_OVERFLOW_HIDDEN_WITH_VISIBLE;
    } else b = function (n) {
      return e[26] = n;
    }, k = DEFAULT_REGEX_OVERFLOW, m = DEFAULT_REGEX_OVERFLOW_WITH_VISIBLE;

    if (a === window || a === g) return b(null), c || uss._containersData.set(a, e), null;

    if (!c) {
      if (!(a instanceof Element)) {
        uss._errorLogger(d.debugString, "the element to be an Element or the Window", a);

        return;
      }

      uss._containersData.set(a, e);
    }

    c = window.getComputedStyle(a);
    if ("fixed" === c.position) return b(null), null;
    var h = "absolute" !== c.position;

    for (a = a.parentElement; a && a !== f && a !== g;) {
      c = window.getComputedStyle(a);
      if ((h || "static" !== c.position) && k.test(c.overflowY) && 1 <= uss.getMaxScrollY(a)) return b(a), a;
      if ("fixed" === c.position) return b(null), null;
      a = a.parentElement;
    }

    if (a === f && "absolute" === c.position) return b(null), null;

    for (d = uss.getWindowScroller(!1, d); a;) {
      c = window.getComputedStyle(a);
      if (m.test(c.overflowY) && 1 <= uss.getMaxScrollY(a)) return d === a && (a = window), b(a), a;
      a = a.parentElement;
    }

    b(null);
    return null;
  },
  getScrollableParent: function (a, b, d) {
    b = void 0 === b ? !1 : b;
    d = void 0 === d ? {
      debugString: "getYScrollableParent"
    } : d;

    var c = uss._containersData.get(a),
        e = c || [];

    if (b) {
      var f = e[25];
      var g = e[27];
    } else f = e[24], g = e[26];

    var k = f || null === f,
        m = g || null === g;
    if (k || m) return k && !m ? g = uss.getYScrollableParent(a, b, d) : !k && m && (f = uss.getXScrollableParent(a, b, d)), f === window || null === f || f.contains(g) ? g : f;
    k = document.body;
    m = document.documentElement;

    if (b) {
      b = function (p) {
        return e[25] = p;
      };

      f = function (p) {
        return e[27] = p;
      };

      var h = DEFAULT_REGEX_OVERFLOW_HIDDEN;
      g = DEFAULT_REGEX_OVERFLOW_HIDDEN_WITH_VISIBLE;
    } else b = function (p) {
      return e[24] = p;
    }, f = function (p) {
      return e[26] = p;
    }, h = DEFAULT_REGEX_OVERFLOW, g = DEFAULT_REGEX_OVERFLOW_WITH_VISIBLE;

    if (a === window || a === m) return b(null), f(null), c || uss._containersData.set(a, e), null;

    if (!c) {
      if (!(a instanceof Element)) {
        uss._errorLogger(d.debugString, "the element to be an Element or the Window", a);

        return;
      }

      uss._containersData.set(a, e);
    }

    c = window.getComputedStyle(a);
    if ("fixed" === c.position) return b(null), f(null), null;
    var n = "absolute" !== c.position;

    for (a = a.parentElement; a && a !== k && a !== m;) {
      c = window.getComputedStyle(a);

      if (n || "static" !== c.position) {
        var l = h.test(c.overflowX) && 1 <= uss.getMaxScrollX(a),
            r = h.test(c.overflowY) && 1 <= uss.getMaxScrollY(a);
        if (l && !r) return b(a), a;
        if (!l && r) return f(a), a;
        if (l && r) return b(a), f(a), a;
      }

      if ("fixed" === c.position) return b(null), f(null), null;
      a = a.parentElement;
    }

    if (a === k && "absolute" === c.position) return b(null), null;

    for (d = uss.getWindowScroller(!1, d); a;) {
      c = window.getComputedStyle(a);
      k = g.test(c.overflowX) && 1 <= uss.getMaxScrollX(a);
      c = g.test(c.overflowY) && 1 <= uss.getMaxScrollY(a);
      d === a && (a = window);
      if (k && !c) return b(a), a;
      if (!k && c) return f(a), a;
      if (k && c) return b(a), f(a), a;
      a = a.parentElement;
    }

    b(null);
    f(null);
    return null;
  },
  getAllScrollableParents: function (a, b, d, c) {
    b = void 0 === b ? !1 : b;
    c = void 0 === c ? {
      debugString: "getAllScrollableParents"
    } : c;
    var e = [],
        f = "function" === typeof d ? d : function () {};

    d = function (g) {
      e.push(g);
      f(g);
    };

    do (a = uss.getScrollableParent(a, b, c)) && d(a); while (a);

    return e;
  },
  scrollXTo: function (a, b, d, c, e) {
    function f(n) {
      var l = h[2],
          r = h[4],
          p = g(),
          t = (l - p) * r;
      if (1 > t) uss.stopScrollingX(b, h[10]);else {
        h[8] || (h[8] = n);
        var w = h[0],
            q = h[14] ? h[14](t, h[8], n, h[6], p, l, b) : h[12] ? h[12](t, h[8], n, h[6], p, l, b) : DEFAULT_XSTEP_LENGTH_CALCULATOR(t, h[8], n, h[6], p, l, b);
        w === h[0] && (l !== h[2] ? h[0] = window.requestAnimationFrame(f) : (Number.isFinite(q) || (uss._warningLogger(q, "is not a valid step length", !0), q = DEFAULT_XSTEP_LENGTH_CALCULATOR(t, h[8], n, h[6], p, l, b)), t <= q ? (k(l), uss.stopScrollingX(b, h[10])) : (k(p + q * r), 0 !== q && p === g() ? uss.stopScrollingX(b, h[10]) : h[0] = window.requestAnimationFrame(f))));
      }
    }

    b = void 0 === b ? uss._pageScroller : b;
    c = void 0 === c ? !1 : c;
    e = void 0 === e ? {
      debugString: "scrollXTo"
    } : e;
    if (Number.isFinite(a)) {
      if (e = uss.getMaxScrollX(b, !1, e), 1 > e) uss._warningLogger(b, "is not scrollable on the x-axis", !1), uss.stopScrollingX(b, d);else {
        c && (0 > a ? a = 0 : a > e && (a = e));
        var g = uss.getScrollXCalculator(b);
        c = a - g();
        e = 0 < c ? 1 : -1;
        c *= e;
        if (1 > c) uss.stopScrollingX(b, d);else {
          var k = b !== window ? function (n) {
            return b.scrollLeft = n;
          } : function (n) {
            return b.scroll(n, window.scrollY);
          };
          if (uss._reducedMotion) k(a), uss.stopScrollingX(b, d);else {
            var m = uss._containersData.get(b),
                h = m || [];

            h[2] = a;
            h[4] = e;
            h[6] = c;
            h[8] = null;
            h[10] = d;
            h[0] || (h[0] = window.requestAnimationFrame(f), m || uss._containersData.set(b, h));
          }
        }
      }
    } else uss._errorLogger(e.debugString, "the finalXPosition to be a number", a);
  },
  scrollYTo: function (a, b, d, c, e) {
    function f(n) {
      var l = h[3],
          r = h[5],
          p = g(),
          t = (l - p) * r;
      if (1 > t) uss.stopScrollingY(b, h[11]);else {
        h[9] || (h[9] = n);
        var w = h[1],
            q = h[15] ? h[15](t, h[9], n, h[7], p, l, b) : h[13] ? h[13](t, h[9], n, h[7], p, l, b) : DEFAULT_YSTEP_LENGTH_CALCULATOR(t, h[9], n, h[7], p, l, b);
        w === h[1] && (l !== h[3] ? h[1] = window.requestAnimationFrame(f) : (Number.isFinite(q) || (uss._warningLogger(q, "is not a valid step length", !0), q = DEFAULT_YSTEP_LENGTH_CALCULATOR(t, h[9], n, h[7], p, l, b)), t <= q ? (k(l), uss.stopScrollingY(b, h[11])) : (k(p + q * r), 0 !== q && p === g() ? uss.stopScrollingY(b, h[11]) : h[1] = window.requestAnimationFrame(f))));
      }
    }

    b = void 0 === b ? uss._pageScroller : b;
    c = void 0 === c ? !1 : c;
    e = void 0 === e ? {
      debugString: "scrollYTo"
    } : e;
    if (Number.isFinite(a)) {
      if (e = uss.getMaxScrollY(b, !1, e), 1 > e) uss._warningLogger(b, "is not scrollable on the y-axis", !1), uss.stopScrollingY(b, d);else {
        c && (0 > a ? a = 0 : a > e && (a = e));
        var g = uss.getScrollYCalculator(b);
        c = a - g();
        e = 0 < c ? 1 : -1;
        c *= e;
        if (1 > c) uss.stopScrollingY(b, d);else {
          var k = b !== window ? function (n) {
            return b.scrollTop = n;
          } : function (n) {
            return b.scroll(window.scrollX, n);
          };
          if (uss._reducedMotion) k(a), uss.stopScrollingY(b, d);else {
            var m = uss._containersData.get(b),
                h = m || [];

            h[3] = a;
            h[5] = e;
            h[7] = c;
            h[9] = null;
            h[11] = d;
            h[1] || (h[1] = window.requestAnimationFrame(f), m || uss._containersData.set(b, h));
          }
        }
      }
    } else uss._errorLogger(e.debugString, "the finalYPosition to be a number", a);
  },
  scrollXBy: function (a, b, d, c, e, f) {
    b = void 0 === b ? uss._pageScroller : b;
    c = void 0 === c ? !0 : c;
    e = void 0 === e ? !1 : e;
    f = void 0 === f ? {
      debugString: "scrollXBy"
    } : f;

    if (Number.isFinite(a)) {
      var g = uss.getScrollXCalculator(b, f)();

      if (!c && (c = uss._containersData.get(b) || [], c[0])) {
        if (0 !== a) {
          var k = c[2] + a;

          if (e) {
            var m = uss.getMaxScrollX(b, !1, f);
            0 > k ? k = 0 : k > m && (k = m);
          }

          g = (k - g) * c[4];

          if (1 > g * g) {
            uss.stopScrollingX(b, d);
            return;
          }

          if (0 > g) {
            uss.scrollXTo(k, b, d, e, f);
            return;
          }

          a = c[6] * c[4] + a;
          c[2] = k;
          c[4] = 0 < a ? 1 : -1;
          c[6] = a * c[4];
        }

        c[8] = null;
        c[10] = d;
        return;
      }

      uss.scrollXTo(g + a, b, d, e, f);
    } else uss._errorLogger(f.debugString, "the deltaX to be a number", a);
  },
  scrollYBy: function (a, b, d, c, e, f) {
    b = void 0 === b ? uss._pageScroller : b;
    c = void 0 === c ? !0 : c;
    e = void 0 === e ? !1 : e;
    f = void 0 === f ? {
      debugString: "scrollYBy"
    } : f;

    if (Number.isFinite(a)) {
      var g = uss.getScrollYCalculator(b, f)();

      if (!c && (c = uss._containersData.get(b) || [], c[1])) {
        if (0 !== a) {
          var k = c[3] + a;

          if (e) {
            var m = uss.getMaxScrollY(b, !1, f);
            0 > k ? k = 0 : k > m && (k = m);
          }

          g = (k - g) * c[5];

          if (1 > g * g) {
            uss.stopScrollingY(b, d);
            return;
          }

          if (0 > g) {
            uss.scrollYTo(k, b, d, e, f);
            return;
          }

          a = c[7] * c[5] + a;
          c[3] = k;
          c[5] = 0 < a ? 1 : -1;
          c[7] = a * c[5];
        }

        c[9] = null;
        c[11] = d;
        return;
      }

      uss.scrollYTo(g + a, b, d, e, f);
    } else uss._errorLogger(f.debugString, "the deltaY to be a number", a);
  },
  scrollTo: function (a, b, d, c, e, f) {
    d = void 0 === d ? uss._pageScroller : d;
    e = void 0 === e ? !1 : e;
    f = void 0 === f ? {
      debugString: "scrollTo"
    } : f;
    if ("function" !== typeof c) uss.scrollXTo(a, d, null, e, f), uss.scrollYTo(b, d, null, e, f);else {
      var g = function () {
        var h = uss._containersData.get(d) || [];
        m || h[11] === k || c();
      },
          k = function () {
        var h = uss._containersData.get(d) || [];
        m || h[10] === g || c();
      },
          m = !0;

      uss.scrollXTo(a, d, g, e, f);
      m = !1;
      uss.scrollYTo(b, d, k, e, f);
    }
  },
  scrollBy: function (a, b, d, c, e, f, g) {
    d = void 0 === d ? uss._pageScroller : d;
    e = void 0 === e ? !0 : e;
    f = void 0 === f ? !1 : f;
    g = void 0 === g ? {
      debugString: "scrollBy"
    } : g;
    if ("function" !== typeof c) uss.scrollXBy(a, d, null, e, f, g), uss.scrollYBy(b, d, null, e, f, g);else {
      var k = function () {
        var n = uss._containersData.get(d) || [];
        h || n[11] === m || c();
      },
          m = function () {
        var n = uss._containersData.get(d) || [];
        h || n[10] === k || c();
      },
          h = !0;

      uss.scrollXBy(a, d, k, e, f, g);
      h = !1;
      uss.scrollYBy(b, d, m, e, f, g);
    }
  },
  scrollIntoView: function (a, b, d, c, e, f) {
    function g() {
      var q = uss.calcScrollbarsDimensions(p, !1),
          u = uss.calcBordersDimensions(p, !1),
          v = p !== window ? p.getBoundingClientRect() : {
        left: 0,
        top: 0,
        width: uss._windowWidth,
        height: uss._windowHeight
      },
          x = v.width,
          B = v.height,
          z = t.getBoundingClientRect(),
          y = z.width,
          A = z.height,
          C = z.left - v.left;
      v = z.top - v.top;

      if (h) {
        z = Math.abs(x - y - C);
        var D = Math.abs(0.5 * (x - y) - C);
        l = (0 < C ? C : -C) < D ? !0 : z < D ? !1 : null;
      }

      n && (z = Math.abs(B - A - v), D = Math.abs(0.5 * (B - A) - v), r = (0 < v ? v : -v) < D ? !0 : z < D ? !1 : null);
      x = Math.round(C - (!0 === l ? u[3] : !1 === l ? x - y - q[0] - u[1] : 0.5 * (x - y - q[0] - u[1] + u[3])));
      q = Math.round(v - (!0 === r ? u[0] : !1 === r ? B - A - q[1] - u[2] : 0.5 * (B - A - q[1] - u[2] + u[0])));
      u = 0 !== x && 1 <= uss.getMaxScrollX(p);
      B = 0 !== q && 1 <= uss.getMaxScrollY(p);
      u && B ? uss.scrollBy(x, q, p, w, !0, !0, f) : u ? uss.scrollXBy(x, p, w, !0, !0, f) : B ? uss.scrollYBy(q, p, w, !0, !0, f) : w();
    }

    b = void 0 === b ? !0 : b;
    d = void 0 === d ? !0 : d;
    f = void 0 === f ? {
      debugString: "scrollIntoView"
    } : f;
    var k = -1,
        m = uss.getAllScrollableParents(a, void 0 === e ? !1 : e, function () {
      return k++;
    }, f);
    if (0 > k) "function" === typeof c && c();else {
      var h = DEFAULT_REGEX_ALIGNMENT_NEAREST.test(b),
          n = DEFAULT_REGEX_ALIGNMENT_NEAREST.test(d),
          l = b,
          r = d,
          p = m[k],
          t = m[k - 1] || a,
          w = function () {
        1 > k ? "function" === typeof c && c() : (k--, p = m[k], t = m[k - 1] || a, g());
      };

      g();
    }
  },
  scrollIntoViewIfNeeded: function (a, b, d, c, e) {
    function f() {
      var t = uss.calcScrollbarsDimensions(l, !1),
          w = uss.calcBordersDimensions(l, !1),
          q = l !== window ? l.getBoundingClientRect() : {
        left: 0,
        top: 0,
        width: uss._windowWidth,
        height: uss._windowHeight
      },
          u = q.width,
          v = q.height,
          x = r.getBoundingClientRect(),
          B = x.width,
          z = x.height,
          y = x.left - q.left;
      q = x.top - q.top;
      var A = r === a;
      x = -1 < y && 1 > y + B - u + t[0];
      var C = -1 < q && 1 > q + z - v + t[1],
          D = 0 >= y && 0 <= y + B - u + t[0],
          E = 0 >= q && 0 <= q + z - v + t[1];
      x = A && !m && (x || D) || !A && x;
      C = A && !m && (C || E) || !A && C;
      x && C ? A ? "function" === typeof d && d() : (g--, l = k[g], r = 1 > g ? a : k[g - 1], f()) : (A && m ? n = h = null : (x || (A = Math.abs(u - B - y), E = Math.abs(0.5 * (u - B) - y), h = (0 < y ? y : -y) < E ? !0 : A < E ? !1 : null), C || (A = Math.abs(v - z - q), E = Math.abs(0.5 * (v - z) - q), n = (0 < q ? q : -q) < E ? !0 : A < E ? !1 : null)), u = Math.round(y - (x ? y : !0 === h ? w[3] : !1 === h ? u - B - t[0] - w[1] : 0.5 * (u - B - t[0] - w[1] + w[3]))), t = Math.round(q - (C ? q : !0 === n ? w[0] : !1 === n ? v - z - t[1] - w[2] : 0.5 * (v - z - t[1] - w[2] + w[0]))), w = 0 !== u && 1 <= uss.getMaxScrollX(l), v = 0 !== t && 1 <= uss.getMaxScrollY(l), w && v ? uss.scrollBy(u, t, l, p, !0, !0, e) : w ? uss.scrollXBy(u, l, p, !0, !0, e) : v ? uss.scrollYBy(t, l, p, !0, !0, e) : p());
    }

    b = void 0 === b ? !0 : b;
    e = void 0 === e ? {
      debugString: "scrollIntoViewIfNeeded"
    } : e;
    var g = -1,
        k = uss.getAllScrollableParents(a, void 0 === c ? !1 : c, function () {
      return g++;
    }, e);
    if (0 > g) "function" === typeof d && d();else {
      var m = !0 === b,
          h = null,
          n = null,
          l = k[g],
          r = k[g - 1] || a,
          p = function () {
        1 > g ? "function" === typeof d && d() : (g--, l = k[g], r = k[g - 1] || a, f());
      };

      f();
    }
  },
  stopScrollingX: function (a, b, d) {
    a = void 0 === a ? uss._pageScroller : a;
    d = void 0 === d ? {
      debugString: "stopScrollingX"
    } : d;

    var c = uss._containersData.get(a);

    if (c) {
      if (window.cancelAnimationFrame(c[0]), c[0] = null, c[10] = null, c[14] = null, !c[1]) {
        d = [];
        c[12] && (d[12] = c[12]);
        c[13] && (d[13] = c[13]);
        c[15] && (d[15] = c[15]);
        Number.isFinite(c[16]) && (d[16] = c[16]);
        Number.isFinite(c[17]) && (d[17] = c[17]);
        Number.isFinite(c[18]) && (d[18] = c[18]);
        Number.isFinite(c[19]) && (d[19] = c[19]);
        Number.isFinite(c[20]) && (d[20] = c[20]);
        Number.isFinite(c[21]) && (d[21] = c[21]);
        Number.isFinite(c[22]) && (d[22] = c[22]);
        Number.isFinite(c[23]) && (d[23] = c[23]);
        if (c[24] || null === c[24]) d[24] = c[24];
        if (c[25] || null === c[25]) d[25] = c[25];
        if (c[26] || null === c[26]) d[26] = c[26];
        if (c[27] || null === c[27]) d[27] = c[27];
        c[28] && (d[28] = c[28]);
        c[29] && (d[29] = c[29]);

        uss._containersData.set(a, d);
      }
    } else {
      if (a !== window && !(a instanceof Element)) {
        uss._errorLogger(d.debugString, "the container to be an Element or the Window", a);

        return;
      }

      uss._containersData.set(a, []);
    }

    "function" === typeof b && b();
  },
  stopScrollingY: function (a, b, d) {
    a = void 0 === a ? uss._pageScroller : a;
    d = void 0 === d ? {
      debugString: "stopScrollingY"
    } : d;

    var c = uss._containersData.get(a);

    if (c) {
      if (window.cancelAnimationFrame(c[1]), c[1] = null, c[11] = null, c[15] = null, !c[0]) {
        d = [];
        c[12] && (d[12] = c[12]);
        c[13] && (d[13] = c[13]);
        c[14] && (d[14] = c[14]);
        Number.isFinite(c[16]) && (d[16] = c[16]);
        Number.isFinite(c[17]) && (d[17] = c[17]);
        Number.isFinite(c[18]) && (d[18] = c[18]);
        Number.isFinite(c[19]) && (d[19] = c[19]);
        Number.isFinite(c[20]) && (d[20] = c[20]);
        Number.isFinite(c[21]) && (d[21] = c[21]);
        Number.isFinite(c[22]) && (d[22] = c[22]);
        Number.isFinite(c[23]) && (d[23] = c[23]);
        if (c[24] || null === c[24]) d[24] = c[24];
        if (c[25] || null === c[25]) d[25] = c[25];
        if (c[26] || null === c[26]) d[26] = c[26];
        if (c[27] || null === c[27]) d[27] = c[27];
        c[28] && (d[28] = c[28]);
        c[29] && (d[29] = c[29]);

        uss._containersData.set(a, d);
      }
    } else {
      if (a !== window && !(a instanceof Element)) {
        uss._errorLogger(d.debugString, "the container to be an Element or the Window", a);

        return;
      }

      uss._containersData.set(a, []);
    }

    "function" === typeof b && b();
  },
  stopScrolling: function (a, b, d) {
    a = void 0 === a ? uss._pageScroller : a;
    d = void 0 === d ? {
      debugString: "stopScrolling"
    } : d;

    var c = uss._containersData.get(a);

    if (c) {
      window.cancelAnimationFrame(c[0]);
      window.cancelAnimationFrame(c[1]);
      c[0] = null;
      c[1] = null;
      c[10] = null;
      c[11] = null;
      d = [];
      c[12] && (d[12] = c[12]);
      c[13] && (d[13] = c[13]);
      Number.isFinite(c[16]) && (d[16] = c[16]);
      Number.isFinite(c[17]) && (d[17] = c[17]);
      Number.isFinite(c[18]) && (d[18] = c[18]);
      Number.isFinite(c[19]) && (d[19] = c[19]);
      Number.isFinite(c[20]) && (d[20] = c[20]);
      Number.isFinite(c[21]) && (d[21] = c[21]);
      Number.isFinite(c[22]) && (d[22] = c[22]);
      Number.isFinite(c[23]) && (d[23] = c[23]);
      if (c[24] || null === c[24]) d[24] = c[24];
      if (c[25] || null === c[25]) d[25] = c[25];
      if (c[26] || null === c[26]) d[26] = c[26];
      if (c[27] || null === c[27]) d[27] = c[27];
      c[28] && (d[28] = c[28]);
      c[29] && (d[29] = c[29]);

      uss._containersData.set(a, d);
    } else {
      if (a !== window && !(a instanceof Element)) {
        uss._errorLogger(d.debugString, "the container to be an Element or the Window", a);

        return;
      }

      uss._containersData.set(a, []);
    }

    "function" === typeof b && b();
  },
  stopScrollingAll: function (a) {
    for (var b = $jscomp.makeIterator(uss._containersData.entries()), d = b.next(); !d.done; d = b.next()) {
      var c = $jscomp.makeIterator(d.value);
      d = c.next().value;
      c = c.next().value;
      window.cancelAnimationFrame(c[0]);
      window.cancelAnimationFrame(c[1]);
      c[0] = null;
      c[1] = null;
      c[10] = null;
      c[11] = null;
      var e = [];
      c[12] && (e[12] = c[12]);
      c[13] && (e[13] = c[13]);
      Number.isFinite(c[16]) && (e[16] = c[16]);
      Number.isFinite(c[17]) && (e[17] = c[17]);
      Number.isFinite(c[18]) && (e[18] = c[18]);
      Number.isFinite(c[19]) && (e[19] = c[19]);
      Number.isFinite(c[20]) && (e[20] = c[20]);
      Number.isFinite(c[21]) && (e[21] = c[21]);
      Number.isFinite(c[22]) && (e[22] = c[22]);
      Number.isFinite(c[23]) && (e[23] = c[23]);
      if (c[24] || null === c[24]) e[24] = c[24];
      if (c[25] || null === c[25]) e[25] = c[25];
      if (c[26] || null === c[26]) e[26] = c[26];
      if (c[27] || null === c[27]) e[27] = c[27];
      c[28] && (e[28] = c[28]);
      c[29] && (e[29] = c[29]);

      uss._containersData.set(d, e);
    }

    "function" === typeof a && a();
  },
  hrefSetup: function (a, b, d, c, e, f, g) {
    a = void 0 === a ? !0 : a;
    b = void 0 === b ? !0 : b;
    e = void 0 === e ? !1 : e;
    f = void 0 === f ? !1 : f;
    g = void 0 === g ? {
      debugString: "hrefSetup"
    } : g;
    var k = "function" === typeof d ? d : function (l, r, p) {
      return p.stopPropagation();
    };
    d = window.location.href.split("#")[0];
    var m = f && !!(window.history && window.history.pushState && window.history.scrollRestoration);
    m && (f = function (l) {
      var r = window.location.hash.slice(1, -1);
      r ? (r = document.getElementById(r) || document.querySelector("a[name='" + r + "']")) && !1 !== k(null, r, l) && uss.scrollIntoView(r, a, b, c, e, g) : !1 !== k(null, uss._pageScroller, l) && uss.scrollTo(0, 0, uss._pageScroller, c, !1, g);
    }, window.history.scrollRestoration = "manual", window.addEventListener("popstate", f, {
      passive: !0
    }), window.addEventListener("unload", function (l) {
      return l.preventDefault();
    }, {
      passive: !1,
      once: !0
    }), "complete" === document.readyState ? f(new Event("load")) : window.addEventListener("load", f, {
      passive: !0,
      once: !0
    }));
    f = {};

    for (var h = $jscomp.makeIterator(document.links), n = h.next(); !n.done; f = {
      $jscomp$loop$prop$_pageLink$13: f.$jscomp$loop$prop$_pageLink$13,
      $jscomp$loop$prop$_elementToReach$14: f.$jscomp$loop$prop$_elementToReach$14,
      $jscomp$loop$prop$_fragment$15: f.$jscomp$loop$prop$_fragment$15
    }, n = h.next()) f.$jscomp$loop$prop$_pageLink$13 = n.value, f.$jscomp$loop$prop$_pageLink$13.href.split("#")[0] === d && (f.$jscomp$loop$prop$_fragment$15 = f.$jscomp$loop$prop$_pageLink$13.hash.substring(1), "" === f.$jscomp$loop$prop$_fragment$15 ? f.$jscomp$loop$prop$_pageLink$13.addEventListener("click", function (l) {
      return function (r) {
        r.preventDefault();
        !1 !== k(l.$jscomp$loop$prop$_pageLink$13, uss._pageScroller, r) && (m && "" !== window.history.state && window.history.pushState("", "", "#"), uss.scrollTo(0, 0, uss._pageScroller, c, !1, g));
      };
    }(f), {
      passive: !1
    }) : (f.$jscomp$loop$prop$_elementToReach$14 = document.getElementById(f.$jscomp$loop$prop$_fragment$15) || document.querySelector("a[name='" + f.$jscomp$loop$prop$_fragment$15 + "']"), f.$jscomp$loop$prop$_elementToReach$14 ? f.$jscomp$loop$prop$_pageLink$13.addEventListener("click", function (l) {
      return function (r) {
        r.preventDefault();
        !1 !== k(l.$jscomp$loop$prop$_pageLink$13, l.$jscomp$loop$prop$_elementToReach$14, r) && (m && window.history.state !== l.$jscomp$loop$prop$_fragment$15 && window.history.pushState(l.$jscomp$loop$prop$_fragment$15, "", "#" + l.$jscomp$loop$prop$_fragment$15 + "."), uss.scrollIntoView(l.$jscomp$loop$prop$_elementToReach$14, a, b, c, e, g));
      };
    }(f), {
      passive: !1
    }) : uss._warningLogger("#" + f.$jscomp$loop$prop$_fragment$15, "is not a valid anchor's destination", !0)));
  }
};
var resizeHandled = !1;
window.addEventListener("resize", function () {
  resizeHandled || (window.addEventListener("pointerover", onResize, {
    passive: !0
  }), window.addEventListener("pointerdown", onResize, {
    passive: !0
  }), window.addEventListener("touchstart", onResize, {
    passive: !0
  }), window.addEventListener("mousemove", onResize, {
    passive: !0
  }), window.addEventListener("keydown", onResize, {
    passive: !0
  }), window.addEventListener("focus", onResize, {
    passive: !0
  }), resizeHandled = !0);
}, {
  passive: !0
});
"complete" === document.readyState ? ussInit() : window.addEventListener("load", ussInit, {
  passive: !0,
  once: !0
});

try {
  window.matchMedia("(prefers-reduced-motion)").addEventListener("change", function () {
    uss._reducedMotion = window.matchMedia("(prefers-reduced-motion)").matches;
    uss.stopScrollingAll();
  }, {
    passive: !0
  });
} catch (a) {
  window.matchMedia("(prefers-reduced-motion)").addListener(function () {
    uss._reducedMotion = window.matchMedia("(prefers-reduced-motion)").matches;
    uss.stopScrollingAll();
  }, {
    passive: !0
  });
}

;
export default {};